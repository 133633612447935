<template>
<div class="app-main__inner">
      <div class="row">
          <div class="col-md-12">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="fa fa-hdd-o icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Dashboard > {{heading}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
                <div class="card">
                  <div class="card-header text-white bg-danger specialColor">
                    {{heading}}
                  </div>
                  <div class="card-body">
                    <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <Collaps :type="type"/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Collaps from '../reuseable/collaps'
export default {
    components: {
    Collaps
  },
  props:['type'],
  computed:{
      heading(){
          if(this.type=='exam')
          return 'Exam Questions'
          else if(this.type=='predict')
          return 'Predict Questions'
          else(this.type=='all')
          return 'Exam & Predict Questions'
      }
  }
}
</script>
